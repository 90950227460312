export default [
    {
        path: '/',
        name: 'about',
        meta: { layout: 'main-layout' },
        component: () => import('@/views/MainLayout/About/About')
    },
    {
        path: '/organizers',
        name: 'organizers',
        meta: { layout: 'main-layout' },
        component: () => import('@/views/MainLayout/Organizers/Organizers')
    },
    {
        path: '/participants',
        name: 'participants',
        meta: { layout: 'main-layout' },
        component: () => import('@/views/MainLayout/Participants/Participants')
    },
    {
        path: '/presentations',
        name: 'presentations',
        meta: { layout: 'main-layout' },
        component: () => import('@/views/MainLayout/Presentations/Presentations')
    },
    {
        path: '/faq',
        name: 'faq',
        meta: { layout: 'main-layout' },
        component: () => import('@/views/MainLayout/Faq/Faq')
    },
    {
        path: '/contacts',
        name: 'contacts',
        meta: { layout: 'main-layout' },
        component: () => import('@/views/MainLayout/Contacts/Contacts')
    },
    {
        path: '/registration-to-conference',
        name: 'registration-to-conference',
        meta: { layout: 'main-layout' },
        component: () => import('@/views/MainLayout/RegisterToConference/RegisterToConference')
    },
    {
        path: '*',
        name: 'not-found-page',
        // meta: { layout: 'main-layout' },
        component: () => import('@/views/notFoundPage')
    },
]